import Uppy, { type UppyFile } from '@uppy/core';
// import { useUppy } from '../../hooks/useUppy';
import { FileInput, useUppy } from '@uppy/react';
import { format, isMatch, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import Select, { type SingleValue } from 'react-select';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type ImportCelebrationDatesRequest } from '../../services/api-service/celebration.api';
import {
  type Celebration,
  type CelebrationParticipant,
} from '../../types/celebration';
import { err2s } from '../../utils/common';
import { csvToArray } from '../../utils/csv';
import { buildReactSelectStyles } from '../../utils/react-select';
import { joinNames } from '../../utils/string';
import { CelebrationUtils } from './utils';

interface TemplateOption {
  label: string;
  value: {
    filename: string;
    birthdayFormat: string;
    displayedBirthdayFormat: string;
    workStartDateFormat: string;
    displayedWorkStartDateFormat: string;
  };
}

const EXAMPLE_EMAIL = 'name@company.com';

const DEFAULT_OPTIONS: TemplateOption[] = [
  {
    label: 'mm/dd/yyyy',
    value: {
      filename: 'celebrations_template_month_day_year',
      birthdayFormat: 'MM/dd',
      displayedBirthdayFormat: 'mm/dd',
      workStartDateFormat: 'MM/dd/yyyy',
      displayedWorkStartDateFormat: 'mm/dd/yyyy',
    },
  },
  {
    label: 'dd/mm/yyyy',
    value: {
      filename: 'celebrations_template_day_month_year',
      birthdayFormat: 'dd/MM',
      displayedBirthdayFormat: 'dd/mm',
      workStartDateFormat: 'dd/MM/yyyy',
      displayedWorkStartDateFormat: 'dd/mm/yyyy',
    },
  },
  {
    label: 'yyyy/mm/dd',
    value: {
      filename: 'celebrations_template_year_month_day',
      birthdayFormat: 'MM/dd',
      displayedBirthdayFormat: 'mm/dd',
      workStartDateFormat: 'yyyy/MM/dd',
      displayedWorkStartDateFormat: 'yyyy/mm/dd',
    },
  },
];

export function CelebrationDatesTemplateDownload(props: {
  participants: CelebrationParticipant[];
}): JSX.Element {
  const styles = useMemo(() => buildReactSelectStyles<TemplateOption>(), []);

  const [option, setOption] = useState<TemplateOption | null>(null);
  const handleChangeTemplate = (option: SingleValue<TemplateOption>) => {
    if (!option) return;
    setOption(option);
  };

  const handleDownload = () => {
    if (!option) return;

    const rows = [
      [
        'Email',
        `Birthday date (${option.value.displayedBirthdayFormat})`,
        `Work start date (${option.value.displayedWorkStartDateFormat})`,
      ],
      [
        EXAMPLE_EMAIL,
        format(new Date(2021, 11, 31), option.value.birthdayFormat),
        format(new Date(2021, 8, 15), option.value.workStartDateFormat),
      ],
      ...props.participants
        .filter((p) => p.channelMember.status !== 'deactivated')
        .map((p) => {
          const birthday = CelebrationUtils.ParseBirthday(p.birthday);
          const workStartDate = CelebrationUtils.ParseWorkStartDate(
            p.workStartDate
          );

          return [
            p.channelMember.email,
            birthday ? format(birthday, option.value.birthdayFormat) : '',
            workStartDate
              ? format(workStartDate, option.value.workStartDateFormat)
              : '',
          ];
        }),
    ];
    const exporter = new ExportToCsv({
      filename: option.value.filename,
    });
    exporter.generateCsv(rows);
  };

  return (
    <div className='flex flex-row gap-6'>
      <Select<TemplateOption>
        classNamePrefix='select-box-v2'
        className='w-48'
        options={DEFAULT_OPTIONS}
        onChange={handleChangeTemplate}
        styles={styles}
        value={option}
        placeholder='Select a date format'
      />
      <button
        type='button'
        className='btn-primary w-48 h-10'
        disabled={!option}
        onClick={handleDownload}
      >
        Download .csv
      </button>
    </div>
  );
}

function CSVUploader(props: {
  id: string;
  onUpload: (header: string[], rows: string[][]) => void;
  children?: React.ReactNode;
}) {
  const fileParser = (currentFile: UppyFile) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result ?? '';
      if (!content) return;

      const csv = csvToArray(content.toString());
      const headers = csv[0];
      const rows = csv.splice(1);

      props.onUpload(headers, rows);
    };

    reader.readAsText(currentFile.data);

    return false;
  };

  const uppy = useUppy(
    () =>
      new Uppy({
        id: props.id,
        autoProceed: false,
        allowMultipleUploads: false,
        debug: true,
        restrictions: {
          maxFileSize: 1024 * 1024,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024,
          maxNumberOfFiles: null,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.csv'],
        },
        locale: {
          strings: {
            chooseFiles: 'Upload .csv',
            exceedsSize: 'Max file size exceeded',
            youCanOnlyUploadFileTypes: 'File type not supported',
          },
        },
        onBeforeFileAdded: fileParser,
      })
  );

  return (
    <label>
      {props.children}
      <div className='hidden'>
        <FileInput uppy={uppy} inputName={'files'} />
      </div>
    </label>
  );
}

interface ImportSummary {
  header: string;
  list: string[];
}

export function CelebrationDatesUploader(props: {
  celebration: Celebration;
  className?: string;
  onUpdate: (success: boolean) => void;
}): JSX.Element {
  const { celebration, onUpdate, className } = props;

  const [summary, setSummary] = useState<ImportSummary | null>(null);

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback((id: string, req: ImportCelebrationDatesRequest) => {
      return apiService.celebration.importDates(id, req);
    }, [])
  );

  const handleUpload = async (headers: string[], rows: string[][]) => {
    const option =
      headers[2] &&
      DEFAULT_OPTIONS.find(
        (op) => headers[2].indexOf(op.value.displayedWorkStartDateFormat) !== -1
      );
    if (!option) {
      setSummary({
        header: 'CSV file errors ❌',
        list: ['Invalid CSV headers'],
      });
      return;
    }

    const noEmailRows: number[] = [];
    const invalidDateRows: number[] = [];
    const notInChannelRows: number[] = [];
    let savedBirthdayCount = 0;
    let savedWorkStartDateCount = 0;

    const req: ImportCelebrationDatesRequest = {
      items: [],
    };
    const items = rows.map((row, index) => ({
      email: row[0]?.trim(),
      birthday: row[1]?.trim(),
      workStartDate: row[2]?.trim(),
      rowNum: index + 1,
    }));
    for (const item of items) {
      if (!item.email) {
        if (!item.birthday && !item.workStartDate) continue;
        noEmailRows.push(item.rowNum);
        continue;
      }
      if (item.email === EXAMPLE_EMAIL) continue;

      const isBirthdayValid =
        !item.birthday || isMatch(item.birthday, option.value.birthdayFormat);
      const isWorkStartDateValid =
        !item.workStartDate ||
        isMatch(item.workStartDate, option.value.workStartDateFormat);
      if (!isBirthdayValid || !isWorkStartDateValid) {
        invalidDateRows.push(item.rowNum);
      }

      req.items.push({
        email: item.email,
        birthday:
          item.birthday && isBirthdayValid
            ? CelebrationUtils.BirthdayFromDate(
                parse(item.birthday, option.value.birthdayFormat, new Date())
              )
            : null,
        workStartDate:
          item.workStartDate && isWorkStartDateValid
            ? CelebrationUtils.WorkStartDateFromDate(
                parse(
                  item.workStartDate,
                  option.value.workStartDateFormat,
                  new Date()
                )
              )
            : null,
      });
    }

    const resp = (await call(celebration.id, req))?.data;
    if (!resp) return;

    for (const result of resp.results) {
      const item = items.find((item) => item.email === result.email);
      if (!item) continue;
      if (result.result === 'success') {
        if (item.birthday) savedBirthdayCount++;
        if (item.workStartDate) savedWorkStartDateCount++;
        continue;
      }
      if (result.result === 'not-in-channel')
        notInChannelRows.push(item.rowNum);
    }

    const summary: ImportSummary = {
      header: '',
      list: [],
    };
    if (noEmailRows.length > 0) {
      summary.list.push(
        `No email in ${pluralize('row', noEmailRows.length)} ${joinNames(
          noEmailRows
        )}`
      );
    }
    if (invalidDateRows.length > 0) {
      summary.list.push(
        `Invalid date in ${pluralize(
          'row',
          invalidDateRows.length
        )} ${joinNames(invalidDateRows)}`
      );
    }
    if (notInChannelRows.length > 0) {
      summary.list.push(
        `No member found in your channel for the ${pluralize(
          'email',
          notInChannelRows.length
        )} in ${pluralize('row', notInChannelRows.length)} ${joinNames(
          notInChannelRows
        )}. Please ensure everyone you’re trying to import has been added to the Channel on Slack first`
      );
    }
    if (summary.list.length > 0) {
      onUpdate(false);
      summary.header = 'CSV file errors ❌';
    } else {
      onUpdate(true);
      summary.header = 'Upload complete ✅';
      summary.list.push(
        `${pluralize('birthday', savedBirthdayCount, true)} saved`
      );
      summary.list.push(
        `${pluralize('work start dates', savedWorkStartDateCount, true)} saved`
      );
    }
    setSummary(summary);
  };

  const handleClear = () => {
    setSummary(null);
  };

  return (
    <div className='flex flex-col gap-3'>
      <CSVUploader id='celebration-uploader' onUpload={handleUpload}>
        <div
          className={`btn-primary flex justify-center items-center ${
            state.isRunning ? 'opacity-50 pointer-events-off' : 'cursor-pointer'
          } ${className}`}
        >
          {state.isRunning
            ? 'Uploading...'
            : !!summary
            ? 'Upload another filled .csv'
            : 'Upload filled .csv'}
        </div>
      </CSVUploader>

      {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}

      {!state.isRunning && summary && (
        <div className='text-sms'>
          <div>
            {summary.header}
            <ul className='list-inside list-disc'>
              {summary.list.map((l) => (
                <li key={l}>{l}</li>
              ))}
            </ul>
          </div>

          <button
            type='button'
            className='btn text-primary mt-2'
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      )}
    </div>
  );
}
